import styled from 'styled-components'
import { device } from '../../styles/variables'
export const S = {};

S.Steps = styled.div`
    display:grid;
`
S.Item = styled.div``

S.Img = styled.img`
    width:100%;
    min-width:200px;
    
`

S.Removal = styled.section`
    padding: 1rem;

    h1,
    h4{
        text-align:left;
    }
    h4{
        border-bottom: 1px solid #00C2FF;
        padding-bottom:1rem;
    }
    @media ${device.mobileL}{
        h1,
        h4{
            max-width:400px;
            margin-left: auto;
            margin-right: auto;
        }
        ${S.Img}{
            max-width:400px;
        }
        ${S.Steps}{
            justify-content:center;
            align-content:center;
        }
        ${S.Item}{
            max-width:400px;
        }
    }
    @media ${device.tablet}{
        padding:2rem;
        p{
            height:90px;
        }
        h1,
        h4{
            max-width:900px;
            text-align:center;
        }
        ${S.Steps}{
            grid-gap:1rem;
            justify-content:center;
            align-content:center;
            grid-template-columns: repeat(3, auto);
        }
        ${S.Item}{
            max-width:280px;
        }
    }
    @media ${device.laptop}{
        ${S.Steps}{
            grid-gap:30px;
        }
    }
`