import styled from 'styled-components'
import { device } from '../../styles/variables'

export const S = {};

S.Title = styled.h1``
S.SubTitle = styled.h4`
    border-bottom: 1px solid #00C2FF;
    padding-bottom:1rem;
`
S.Reviews = styled.div`
    display:grid;
    grid-gap:1rem;
`
S.Item = styled.div`
    box-sizing: border-box;
    padding:1rem;
    background-color:white;
    border-radius:10px;
    box-shadow: 0px 1px 4px #E3E3E3;
`
S.ItemTitle = styled.h3``
S.Text = styled.p`
    height:50px;
`

S.Testimonial = styled.section`
    padding: 1rem;
    background-color:#f1f1f1;

    h1,
    h4{
        text-align:left;
    }    
    @media ${device.mobileL}{
        h1,
        h4{
            max-width:400px;
            margin-left: auto;
            margin-right: auto;
        }
        ${S.Item}{
            max-width:400px;
        }
        ${S.Reviews}{
            justify-content:center;
            align-content:center;
        }

    }
    @media ${device.tablet}{
        padding:2rem;
        h1,
        h4{
            max-width:900px;
            text-align:center;
        }        
        ${S.Reviews}{
            justify-content:center;
            align-content:center;
            grid-template-columns: repeat(3, auto);
        }
        ${S.Item}{
            max-width:280px;
        }
    }
    @media ${device.laptop}{
        ${S.Reviews}{
            grid-gap:30px;
        }
    }
`