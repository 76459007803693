import React from 'react'
import { S } from './style'
import inspection from '../../images/inspection.png'
import repair from '../../images/repair.png'
import delivery from '../../images/delivery.png'

export default () => {
    return(
        <S.Removal>
            <h1>DENT REMOVAL PROCESS</h1>
            <h4>Preserves the integrity of the original paint</h4>
            <S.Steps>
                <S.Item>
                    <h3>INSPECTION</h3>
                    <p>It starts with the rigorous quality control inspection to ensure that all dents have been located and repair work can be performed.</p>
                    <S.Img src={inspection}/>
                </S.Item>
                <S.Item>
                    <h3>DENT REPAIR</h3>
                    <p>After the evaluation, Andy begins the dent removal process carefully performing the dent repair work.</p>
                    <S.Img src={repair}/>
                </S.Item>
                <S.Item>
                    <h3>DELIVERY</h3>
                    <p>Once the vehicle is ready, you'll be contacted for the final inspection and documentation.</p>
                    <S.Img src={delivery}/>
                </S.Item>
            </S.Steps>
        </S.Removal>
    )
}