import React from 'react'
import { S } from './style'

export default () => {
    return(
        <S.Testimonial>
            <S.Title>TESTIMONIALS</S.Title>
            <S.SubTitle>This is what people are saying about us!</S.SubTitle>
            <S.Reviews>
                <S.Item>
                    <S.Text>'Whoa! What can I say, he's amazing at what he does'</S.Text>
                    <S.ItemTitle>- John S.</S.ItemTitle>
                </S.Item>
                <S.Item>
                    <S.Text>'Andy went above and beyond our expectations'</S.Text>
                    <S.ItemTitle>- David M.</S.ItemTitle>
                </S.Item>
                <S.Item>
                    <S.Text>'Hands down the best dent repair in San Antonio'</S.Text>
                    <S.ItemTitle>- Chris F.</S.ItemTitle>
                </S.Item>
            </S.Reviews>
        </S.Testimonial>
    )
}