import React from "react"
import Layout from '../layout'
import SEO from '../components/seo'
import andy from '../images/andy.png'
import { G } from '../styles/components'
import About from '../components/about' 
import Removal from '../components/dent_removal'
import Testimonials from '../components/testimonials'
import content from '../../data/content'

export default () => {
    const { 
        home:{
            header,
            about,
        }
    } = content;

    return(
        <Layout>
        <SEO  title='Home'/>

        <G.Title>{header}</G.Title>
        <About title={about.title} text={about.text} img={andy}/>
        
        <Removal />
        <Testimonials/>
        {/* <G.Questions>
            <h1>QUESTIONS / DIRECTIONS</h1>
            <G.ButtonContainer>
                <G.Button>CALL TODAY</G.Button>
                <G.Button>LOCATION</G.Button>
            </G.ButtonContainer>
        </G.Questions> */}
    </Layout>
    )
}

